import React from 'react';
import { X } from 'lucide-react';

interface TermsAndConditionsProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function TermsAndConditions({ isOpen, onClose }: TermsAndConditionsProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-900 rounded-xl border border-gray-800 w-full max-w-4xl h-[80vh] flex flex-col">
        <div className="p-6 border-b border-gray-800 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-white">Terms and Conditions</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        
        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          <section>
            <h3 className="text-xl font-semibold text-white mb-4">1. Introduction</h3>
            <p className="text-gray-300">
              Welcome to Gold High Yield Investment. By accessing or using our platform, you agree to be bound by these terms and conditions. Please read them carefully before proceeding with any investment activities.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">2. Eligibility</h3>
            <p className="text-gray-300">
              To use our services, you must be at least 18 years old and have the legal capacity to enter into binding contracts. You must provide accurate and complete information during registration and keep your account information updated.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">3. Investment Terms</h3>
            <div className="space-y-3 text-gray-300">
              <p>Our investment plans operate under the following terms:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Minimum investment amount: $2,000</li>
                <li>Weekly plan: Guaranteed 30% return on investment</li>
                <li>Monthly plan: Guaranteed 50% return on investment</li>
                <li>Early withdrawal penalty: 20% of invested capital</li>
                <li>Returns are paid directly to your account</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">4. Account Security</h3>
            <div className="space-y-3 text-gray-300">
              <p>You are responsible for:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Maintaining the confidentiality of your account credentials</li>
                <li>All activities that occur under your account</li>
                <li>Enabling two-factor authentication when available</li>
                <li>Notifying us immediately of any unauthorized access</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">5. Early Withdrawal Policy</h3>
            <div className="space-y-3 text-gray-300">
              <p>
                By accepting these terms, you acknowledge and agree that:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Early withdrawal before the completion of your chosen plan (weekly or monthly) will result in:</li>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Forfeiture of all accumulated returns</li>
                  <li>A 20% penalty fee deducted from your initial investment capital</li>
                </ul>
                <li>This penalty is non-negotiable and represents compensation for breach of investment agreement</li>
                <li>The remaining 80% of your capital will be processed within 24 hours</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">6. Payments and Withdrawals</h3>
            <div className="space-y-3 text-gray-300">
              <p>
                We accept payments in Bitcoin (BTC), Ethereum (ETH), and USDT (TRC20). All withdrawals are subject to:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Minimum withdrawal amount: $100</li>
                <li>Identity verification requirements</li>
                <li>Security checks and processing times</li>
                <li>Blockchain network fees</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">7. Privacy and Data Protection</h3>
            <p className="text-gray-300">
              We are committed to protecting your privacy and personal data. Our collection and use of your information is governed by our Privacy Policy, which is incorporated into these terms by reference.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">8. Prohibited Activities</h3>
            <div className="space-y-3 text-gray-300">
              <p>Users are prohibited from:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Using the platform for any illegal purposes</li>
                <li>Attempting to manipulate or abuse the investment system</li>
                <li>Providing false or misleading information</li>
                <li>Interfering with platform security or functionality</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">9. Termination</h3>
            <p className="text-gray-300">
              We reserve the right to suspend or terminate your account for any violation of these terms or suspicious activity. Upon termination, withdrawal of remaining balance will be subject to our withdrawal terms and early withdrawal penalties if applicable.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">10. Changes to Terms</h3>
            <p className="text-gray-300">
              We may modify these terms at any time. Continued use of the platform after changes constitutes acceptance of the modified terms. We will notify users of significant changes via email or platform notifications.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">11. Governing Law</h3>
            <p className="text-gray-300">
              These terms are governed by and construed in accordance with applicable international laws. Any disputes shall be resolved through arbitration in accordance with international arbitration rules.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">12. Contact Information</h3>
            <p className="text-gray-300">
              For any questions or concerns regarding these terms, please contact our support team at investment@goldhyi.com.
            </p>
          </section>
        </div>

        <div className="p-6 border-t border-gray-800">
          <button
            onClick={onClose}
            className="w-full px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
          >
            I Understand and Accept
          </button>
        </div>
      </div>
    </div>
  );
}