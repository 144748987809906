import React from 'react';
import CountUp from 'react-countup';
import { TrendingUp, ArrowUpRight, Coins, DollarSign, LineChart, PieChart } from 'lucide-react';
import { useUser } from '@clerk/clerk-react';

export default function DashboardHome() {
  const { user } = useUser();
  const investments = user?.publicMetadata?.investments || [];

  // Calculate portfolio metrics
  const totalPortfolioValue = investments.reduce((total: number, inv: any) => total + inv.amount, 0);
  const activeInvestments = investments.length;
  const monthlyYield = investments.reduce((total: number, inv: any) => {
    const monthlyReturn = inv.duration === 'weekly' 
      ? inv.amount * 0.3 * 4  // 30% weekly = ~120% monthly
      : inv.amount * 0.5;     // 50% monthly
    return total + monthlyReturn;
  }, 0);
  
  // Calculate ROI
  const totalInvested = totalPortfolioValue;
  const totalReturns = monthlyYield;
  const roi = totalInvested > 0 ? (totalReturns / totalInvested) * 100 : 0;

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {/* Portfolio Value Card */}
          <div className="bg-black/40 p-6 rounded-xl border border-gray-800">
            <div className="flex items-center justify-between mb-4">
              <div className="bg-emerald-500/20 p-2 rounded-lg">
                <TrendingUp className="h-6 w-6 text-emerald-500" />
              </div>
              <span className="text-emerald-500 flex items-center">
                <ArrowUpRight className="h-4 w-4 mr-1" />
                Active
              </span>
            </div>
            <h3 className="text-gray-400 text-sm">Total Portfolio Value</h3>
            <div className="text-2xl font-bold text-white mt-2">
              ${totalPortfolioValue > 0 ? (
                <CountUp end={totalPortfolioValue} decimals={2} duration={2.5} />
              ) : (
                '0.00'
              )}
            </div>
          </div>

          {/* Active Investments Card */}
          <div className="bg-black/40 p-6 rounded-xl border border-gray-800">
            <div className="flex items-center justify-between mb-4">
              <div className="bg-blue-500/20 p-2 rounded-lg">
                <Coins className="h-6 w-6 text-blue-500" />
              </div>
              <span className="text-blue-500 flex items-center">
                <ArrowUpRight className="h-4 w-4 mr-1" />
                Active
              </span>
            </div>
            <h3 className="text-gray-400 text-sm">Active Investments</h3>
            <div className="text-2xl font-bold text-white mt-2">
              <CountUp end={activeInvestments} duration={2} />
            </div>
          </div>

          {/* Monthly Yield Card */}
          <div className="bg-black/40 p-6 rounded-xl border border-gray-800">
            <div className="flex items-center justify-between mb-4">
              <div className="bg-purple-500/20 p-2 rounded-lg">
                <DollarSign className="h-6 w-6 text-purple-500" />
              </div>
              <span className="text-purple-500 flex items-center">
                <ArrowUpRight className="h-4 w-4 mr-1" />
                Monthly
              </span>
            </div>
            <h3 className="text-gray-400 text-sm">Monthly Yield</h3>
            <div className="text-2xl font-bold text-white mt-2">
              ${monthlyYield > 0 ? (
                <CountUp end={monthlyYield} decimals={2} duration={2.5} />
              ) : (
                '0.00'
              )}
            </div>
          </div>

          {/* ROI Card */}
          <div className="bg-black/40 p-6 rounded-xl border border-gray-800">
            <div className="flex items-center justify-between mb-4">
              <div className="bg-orange-500/20 p-2 rounded-lg">
                <LineChart className="h-6 w-6 text-orange-500" />
              </div>
              <span className="text-orange-500 flex items-center">
                <ArrowUpRight className="h-4 w-4 mr-1" />
                Current
              </span>
            </div>
            <h3 className="text-gray-400 text-sm">ROI</h3>
            <div className="text-2xl font-bold text-white mt-2">
              <CountUp end={roi} decimals={2} duration={2.5} />%
            </div>
          </div>
        </div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 bg-black/40 p-6 rounded-xl border border-gray-800">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-bold text-white">Portfolio Performance</h2>
              <select className="bg-gray-800 text-white rounded-lg px-3 py-1 text-sm">
                <option>Last 7 Days</option>
                <option>Last 30 Days</option>
                <option>Last 90 Days</option>
              </select>
            </div>
            <div className="h-[300px] flex items-center justify-center">
              <div className="text-gray-500">
                {investments.length > 0 ? 'Performance chart will be available soon' : 'No investment data available'}
              </div>
            </div>
          </div>

          <div className="bg-black/40 p-6 rounded-xl border border-gray-800">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-bold text-white">Asset Distribution</h2>
              <PieChart className="h-5 w-5 text-gray-400" />
            </div>
            {investments.length > 0 ? (
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-3 h-3 rounded-full bg-emerald-500 mr-2"></div>
                    <span className="text-gray-300">Weekly Plans</span>
                  </div>
                  <span className="text-white font-medium">
                    {((investments.filter((i: any) => i.duration === 'weekly').length / investments.length) * 100).toFixed(0)}%
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-3 h-3 rounded-full bg-blue-500 mr-2"></div>
                    <span className="text-gray-300">Monthly Plans</span>
                  </div>
                  <span className="text-white font-medium">
                    {((investments.filter((i: any) => i.duration === 'monthly').length / investments.length) * 100).toFixed(0)}%
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-center py-8 text-gray-500">
                No investment data available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}