import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Portfolio from './Portfolio';
import Markets from './Markets';
import History from './History';
import Profile from './Profile';
import DashboardHome from './DashboardHome';
import Footer from './Footer';

export default function Dashboard() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-900">
      <Navbar />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<DashboardHome />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/history" element={<History />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Navigate to="." />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}