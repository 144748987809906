import React, { useState } from 'react';
import { Mail } from 'lucide-react';
import TermsAndConditions from './TermsAndConditions';

export default function Footer() {
  const [showTerms, setShowTerms] = useState(false);

  return (
    <>
      <footer className="bg-black/95 text-gray-400 py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <p className="text-sm">
                © {new Date().getFullYear()} GOLD HIGH YIELD INVESTMENT. All rights reserved.
              </p>
            </div>
            <div className="flex items-center space-x-6">
              <button
                onClick={() => setShowTerms(true)}
                className="text-sm hover:text-emerald-500 transition-colors"
              >
                Terms & Conditions
              </button>
              <div className="flex items-center">
                <Mail className="h-4 w-4 mr-2" />
                <a
                  href="mailto:investment@goldhyi.com"
                  className="text-sm hover:text-emerald-500 transition-colors"
                >
                  investment@goldhyi.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <TermsAndConditions 
        isOpen={showTerms}
        onClose={() => setShowTerms(false)}
      />
    </>
  );
}