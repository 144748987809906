import React from 'react';
import { CheckCircle, Clock } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

interface Transaction {
  id: string;
  type: 'investment' | 'withdrawal' | 'yield';
  amount: number;
  date: string;
  status: 'completed' | 'processing' | 'failed';
  currency: string;
}

export default function History() {
  const { user } = useAuth();
  const transactions = user?.transactions || [];

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-bold text-white mb-8">Transaction History</h1>

        <div className="bg-black/40 rounded-xl border border-gray-800 overflow-hidden">
          <div className="p-6">
            <h2 className="text-xl font-bold text-white mb-4">Recent Transactions</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-800/50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Transaction ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Type</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Amount</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Status</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {transactions.length > 0 ? (
                  transactions.map((transaction: Transaction) => (
                    <tr key={transaction.id} className="hover:bg-gray-800/30">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {transaction.id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 py-1 text-xs rounded-full ${
                          transaction.type === 'investment' ? 'bg-blue-500/20 text-blue-500' :
                          transaction.type === 'withdrawal' ? 'bg-purple-500/20 text-purple-500' :
                          'bg-emerald-500/20 text-emerald-500'
                        }`}>
                          {transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        ${transaction.amount.toLocaleString()} {transaction.currency}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {new Date(transaction.date).toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          {transaction.status === 'completed' ? (
                            <CheckCircle className="h-4 w-4 text-emerald-500 mr-2" />
                          ) : (
                            <Clock className="h-4 w-4 text-blue-500 mr-2" />
                          )}
                          <span className={`text-sm ${
                            transaction.status === 'completed' ? 'text-emerald-500' :
                            transaction.status === 'processing' ? 'text-blue-500' :
                            'text-red-500'
                          }`}>
                            {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="px-6 py-8 text-center text-gray-400">
                      No transactions yet
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}