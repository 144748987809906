import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, X, Wallet, Bell, LogOut, User } from 'lucide-react';
import { useUser, useClerk } from '@clerk/clerk-react';

export default function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);
  const { user } = useUser();
  const { signOut } = useClerk();
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    { id: 'dashboard', label: 'Dashboard', path: '/dashboard' },
    { id: 'markets', label: 'Markets', path: '/dashboard/markets' },
    { id: 'portfolio', label: 'Portfolio', path: '/dashboard/portfolio' },
    { id: 'history', label: 'History', path: '/dashboard/history' }
  ];

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <nav className="bg-black/95 fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0 flex items-center">
              <Wallet className="h-8 w-8 text-emerald-500" />
              <span className="ml-2 text-white font-bold">Gold High Yield</span>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {navItems.map(item => (
                  <button
                    key={item.id}
                    onClick={() => navigate(item.path)}
                    className={`px-3 py-2 rounded-md text-sm font-medium ${
                      location.pathname === item.path
                        ? 'text-white bg-gray-800'
                        : 'text-gray-300 hover:text-white'
                    }`}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <button className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none">
                <Bell className="h-6 w-6" />
              </button>
              <button
                onClick={() => navigate('/dashboard/profile')}
                className={`p-1 rounded-full text-gray-400 hover:text-white focus:outline-none ml-2 ${
                  location.pathname === '/dashboard/profile' ? 'text-white bg-gray-800' : ''
                }`}
              >
                <User className="h-6 w-6" />
              </button>
              <div className="ml-3 flex items-center">
                <span className="text-sm font-medium text-white mr-4">
                  {user?.primaryEmailAddress?.emailAddress}
                </span>
                <button
                  onClick={handleLogout}
                  className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none"
                >
                  <LogOut className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white focus:outline-none"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map(item => (
              <button
                key={item.id}
                onClick={() => {
                  navigate(item.path);
                  setIsOpen(false);
                }}
                className={`block px-3 py-2 rounded-md text-base font-medium w-full text-left ${
                  location.pathname === item.path
                    ? 'text-white bg-gray-800'
                    : 'text-gray-300 hover:text-white'
                }`}
              >
                {item.label}
              </button>
            ))}
            <button
              onClick={() => {
                navigate('/dashboard/profile');
                setIsOpen(false);
              }}
              className={`block px-3 py-2 rounded-md text-base font-medium w-full text-left ${
                location.pathname === '/dashboard/profile'
                  ? 'text-white bg-gray-800'
                  : 'text-gray-300 hover:text-white'
              }`}
            >
              Profile
            </button>
            <button
              onClick={handleLogout}
              className="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium w-full text-left"
            >
              Sign out
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}