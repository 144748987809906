import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { AlertCircle, Shield } from 'lucide-react';

export default function AdminSetup() {
  const navigate = useNavigate();
  const { user } = useUser();
  const [error, setError] = useState('');
  const [isConfiguring, setIsConfiguring] = useState(false);

  const handleSetupComplete = async () => {
    try {
      setIsConfiguring(true);
      // In a real application, you would make API calls to set up admin configuration
      // For now, we'll just simulate a delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      navigate('/admin');
    } catch (err) {
      setError('Failed to complete admin setup');
    } finally {
      setIsConfiguring(false);
    }
  };

  if (!user) {
    navigate('/');
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-black/40 rounded-xl border border-gray-800 p-8">
          <div className="text-center mb-8">
            <Shield className="mx-auto h-12 w-12 text-emerald-500" />
            <h2 className="mt-6 text-3xl font-bold text-white">Admin Setup</h2>
            <p className="mt-2 text-gray-400">
              Configure your admin account and platform settings
            </p>
          </div>

          {error && (
            <div className="mb-6 bg-red-500/10 border border-red-500 text-red-500 px-4 py-3 rounded-lg flex items-center">
              <AlertCircle className="h-5 w-5 mr-2" />
              <span>{error}</span>
            </div>
          )}

          <div className="space-y-6">
            <div className="bg-gray-800/50 rounded-lg p-4">
              <h3 className="text-lg font-medium text-white mb-2">Admin Account</h3>
              <p className="text-gray-400">
                Email: {user.primaryEmailAddress?.emailAddress}
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-lg p-4">
              <h3 className="text-lg font-medium text-white mb-2">Platform Settings</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Minimum Investment Amount
                  </label>
                  <input
                    type="number"
                    defaultValue={2000}
                    className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-emerald-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Weekly Return Rate (%)
                  </label>
                  <input
                    type="number"
                    defaultValue={30}
                    className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-emerald-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Monthly Return Rate (%)
                  </label>
                  <input
                    type="number"
                    defaultValue={50}
                    className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-emerald-500"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={handleSetupComplete}
                disabled={isConfiguring}
                className="px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 flex items-center"
              >
                {isConfiguring ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white mr-2"></div>
                    Configuring...
                  </>
                ) : (
                  'Complete Setup'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}