import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Wallet, ArrowRight, Bitcoin, DollarSign, Clock, ArrowLeft, Send, X } from 'lucide-react';
import { useUser } from '@clerk/clerk-react';
import { useCryptoStore } from '../services/CryptoService';

interface Investment {
  id: string;
  amount: number;
  date: string;
  duration: 'weekly' | 'monthly';
  status: 'active' | 'completed';
}

export default function Portfolio() {
  const { user } = useUser();
  const [amount, setAmount] = useState<number>(2000);
  const [payoutOption, setPayoutOption] = useState<'weekly' | 'monthly'>('monthly');
  const [selectedCrypto, setSelectedCrypto] = useState<string>('');
  const { rates: cryptoRates, error: ratesError, fetchRates, lastUpdated } = useCryptoStore();
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [paymentId, setPaymentId] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [showWithdrawal, setShowWithdrawal] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState({
    amount: '',
    cryptoType: 'BTC',
    address: ''
  });
  const [withdrawalError, setWithdrawalError] = useState('');

  const investments = (user?.publicMetadata?.investments as Investment[]) || [];
  const totalPortfolioValue = investments.reduce((total, inv) => total + inv.amount, 0);

  const cryptoAddresses = {
    BTC: 'bc1q6t57nqrzsks2g0wvcn240myp9lz89d3wm8dlnz',
    ETH: '0x35Bca8F8DBFD9d351f7B04F7b04333cbB98700f8',
    USDT: 'TGSau5oxnrcqbCoR7jZFCxdWbowH1n86SD' // USDT TRC20 address
  };

  React.useEffect(() => {
    fetchRates();
    const interval = setInterval(fetchRates, 30000);
    return () => clearInterval(interval);
  }, [fetchRates]);

  const handleStartInvestment = () => {
    setShowPaymentMethod(true);
  };

  const handlePaymentMethodSelect = (crypto: string) => {
    setSelectedCrypto(crypto);
    setPaymentId(Date.now().toString());
  };

  const handleWithdrawalSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const withdrawalAmount = parseFloat(withdrawalData.amount);

    if (withdrawalAmount > totalPortfolioValue) {
      setWithdrawalError('Withdrawal amount exceeds portfolio value');
      return;
    }

    if (withdrawalAmount < 100) {
      setWithdrawalError('Minimum withdrawal amount is $100');
      return;
    }

    alert('Withdrawal request submitted successfully! Our support team will review your request within 24 hours.');
    setShowWithdrawal(false);
    setWithdrawalData({
      amount: '',
      cryptoType: 'BTC',
      address: ''
    });
    setWithdrawalError('');
  };

  const handleBack = () => {
    if (selectedCrypto) {
      setSelectedCrypto('');
    } else {
      setShowPaymentMethod(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Portfolio Summary */}
        <div className="bg-black/40 rounded-xl border border-gray-800 p-6 mb-8">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-2xl font-bold text-white mb-2">Your Portfolio</h2>
              <p className="text-gray-400">Total Value: ${totalPortfolioValue.toLocaleString()}</p>
            </div>
            <button
              onClick={() => setShowWithdrawal(true)}
              className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors flex items-center"
            >
              <Send className="h-5 w-5 mr-2" />
              Withdraw Funds
            </button>
          </div>
        </div>

        {/* Investment Form */}
        {!showPaymentMethod && (
          <div className="bg-black/40 rounded-xl border border-gray-800 p-6">
            <h3 className="text-xl font-bold text-white mb-6">Start New Investment</h3>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Investment Amount (USD)
                </label>
                <div className="relative">
                  <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(Math.max(2000, parseFloat(e.target.value)))}
                    min="2000"
                    step="100"
                    className="block w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-emerald-500"
                    placeholder="Minimum $2,000"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-400">Minimum investment: $2,000</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Payout Option
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={() => setPayoutOption('weekly')}
                    className={`p-4 rounded-lg border ${
                      payoutOption === 'weekly'
                        ? 'border-emerald-500 bg-emerald-500/10 text-white'
                        : 'border-gray-700 bg-gray-800 text-gray-400'
                    }`}
                  >
                    <div className="text-lg font-bold mb-1">Weekly</div>
                    <div className="text-sm">30% Return</div>
                  </button>
                  <button
                    onClick={() => setPayoutOption('monthly')}
                    className={`p-4 rounded-lg border ${
                      payoutOption === 'monthly'
                        ? 'border-emerald-500 bg-emerald-500/10 text-white'
                        : 'border-gray-700 bg-gray-800 text-gray-400'
                    }`}
                  >
                    <div className="text-lg font-bold mb-1">Monthly</div>
                    <div className="text-sm">50% Return</div>
                  </button>
                </div>
              </div>

              <div>
                <h4 className="text-lg font-medium text-white mb-4">Projected Returns</h4>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="bg-gray-800 p-4 rounded-lg">
                    <div className="text-sm text-gray-400 mb-1">1 Month</div>
                    <div className="text-lg font-bold text-white">
                      ${(amount * (payoutOption === 'weekly' ? 1.3 : 1.5)).toLocaleString()}
                    </div>
                  </div>
                  <div className="bg-gray-800 p-4 rounded-lg">
                    <div className="text-sm text-gray-400 mb-1">3 Months</div>
                    <div className="text-lg font-bold text-white">
                      ${(amount * Math.pow(payoutOption === 'weekly' ? 1.3 : 1.5, 3)).toLocaleString()}
                    </div>
                  </div>
                  <div className="bg-gray-800 p-4 rounded-lg">
                    <div className="text-sm text-gray-400 mb-1">6 Months</div>
                    <div className="text-lg font-bold text-white">
                      ${(amount * Math.pow(payoutOption === 'weekly' ? 1.3 : 1.5, 6)).toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  onClick={handleStartInvestment}
                  className="px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors flex items-center"
                >
                  Start Investment
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Payment Method Selection */}
        {showPaymentMethod && !selectedCrypto && (
          <div className="bg-black/40 rounded-xl border border-gray-800 p-6">
            <div className="flex items-center mb-6">
              <button
                onClick={handleBack}
                className="mr-4 p-2 hover:bg-gray-800 rounded-lg transition-colors"
              >
                <ArrowLeft className="h-5 w-5 text-gray-400" />
              </button>
              <h3 className="text-xl font-bold text-white">Select Payment Method</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {Object.entries(cryptoRates).map(([crypto, { rate, symbol }]) => (
                <button
                  key={crypto}
                  onClick={() => handlePaymentMethodSelect(crypto)}
                  className="p-4 rounded-lg border border-gray-700 bg-gray-800 hover:border-emerald-500 transition-colors"
                >
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-lg font-bold text-white">{crypto}</span>
                    <span className="text-gray-400">{symbol}</span>
                  </div>
                  <div className="text-sm text-gray-400">
                    Rate: ${rate.toLocaleString()}
                  </div>
                  <div className="text-sm text-gray-400">
                    ≈ {(amount / rate).toFixed(8)} {crypto}
                  </div>
                  {crypto === 'USDT' && (
                    <div className="mt-2 text-xs text-emerald-500">TRC20 Network</div>
                  )}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Payment Details */}
        {showPaymentMethod && selectedCrypto && (
          <div className="bg-black/40 rounded-xl border border-gray-800 p-6">
            <div className="flex items-center mb-6">
              <button
                onClick={handleBack}
                className="mr-4 p-2 hover:bg-gray-800 rounded-lg transition-colors"
              >
                <ArrowLeft className="h-5 w-5 text-gray-400" />
              </button>
              <h3 className="text-xl font-bold text-white">Payment Details</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <div className="bg-gray-800 p-6 rounded-lg mb-4">
                  <h4 className="text-lg font-medium text-white mb-4">Send Payment</h4>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm text-gray-400 mb-1">Amount</label>
                      <div className="text-xl font-bold text-white">
                        {(amount / cryptoRates[selectedCrypto as keyof typeof cryptoRates].rate).toFixed(8)} {selectedCrypto}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm text-gray-400 mb-1">
                        {selectedCrypto === 'USDT' ? 'USDT (TRC20) Address' : `${selectedCrypto} Address`}
                      </label>
                      <div className="bg-gray-900 p-3 rounded-lg break-all font-mono text-white">
                        {cryptoAddresses[selectedCrypto as keyof typeof cryptoAddresses]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-800 p-6 rounded-lg">
                  <QRCodeSVG
                    value={cryptoAddresses[selectedCrypto as keyof typeof cryptoAddresses]}
                    size={200}
                    className="mx-auto"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col items-center justify-center p-6 bg-gray-800/50 rounded-lg">
                  <Clock className="h-12 w-12 text-emerald-500 animate-spin mb-4" />
                  <h3 className="text-lg font-semibold text-white mb-2">Awaiting Payment</h3>
                  <p className="text-gray-400 text-center">
                    Please send the exact amount to the provided address.
                    The transaction will be confirmed automatically.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Withdrawal Form */}
        {showWithdrawal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-gray-900 rounded-xl border border-gray-800 p-6 max-w-md w-full">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-bold text-white">Withdraw Funds</h3>
                <button
                  onClick={() => {
                    setShowWithdrawal(false);
                    setWithdrawalError('');
                  }}
                  className="text-gray-400 hover:text-white"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>

              <form onSubmit={handleWithdrawalSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Withdrawal Amount (USD)
                  </label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <input
                      type="number"
                      value={withdrawalData.amount}
                      onChange={(e) => setWithdrawalData({ ...withdrawalData, amount: e.target.value })}
                      min="100"
                      step="1"
                      required
                      className="block w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-emerald-500"
                      placeholder="Minimum $100"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Select Cryptocurrency
                  </label>
                  <select
                    value={withdrawalData.cryptoType}
                    onChange={(e) => setWithdrawalData({ ...withdrawalData, cryptoType: e.target.value })}
                    className="block w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-emerald-500"
                    required
                  >
                    <option value="BTC">Bitcoin (BTC)</option>
                    <option value="ETH">Ethereum (ETH)</option>
                    <option value="USDT">USDT (TRC20)</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    {withdrawalData.cryptoType === 'USDT' ? 'USDT (TRC20) Address' : `${withdrawalData.cryptoType} Address`}
                  </label>
                  <input
                    type="text"
                    value={withdrawalData.address}
                    onChange={(e) => setWithdrawalData({ ...withdrawalData, address: e.target.value })}
                    className="block w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-emerald-500"
                    placeholder={`Enter your ${withdrawalData.cryptoType} address`}
                    required
                  />
                </div>

                {withdrawalError && (
                  <div className="text-red-500 text-sm">{withdrawalError}</div>
                )}

                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      setShowWithdrawal(false);
                      setWithdrawalError('');
                    }}
                    className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors flex items-center"
                  >
                    <Send className="h-5 w-5 mr-2" />
                    Submit Withdrawal
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}