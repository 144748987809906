import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import AdminSetup from './AdminSetup';
import AdminNavbar from './AdminNavbar';
import AdminChat from './AdminChat';

export default function Admin() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-900">
      <AdminNavbar />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<AdminDashboard />} />
          <Route path="/setup" element={<AdminSetup />} />
          <Route path="*" element={<Navigate to="." />} />
        </Routes>
      </main>
      <AdminChat />
    </div>
  );
}