import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ClerkProvider } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';
import { AuthProvider } from './context/AuthContext';
import LandingPage from './components/LandingPage';
import Dashboard from './components/Dashboard';
import Admin from './components/Admin';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import ChatBot from './components/ChatBot';

if (!import.meta.env.VITE_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

const clerkPubKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

const customAppearance = {
  baseTheme: dark,
  elements: {
    card: 'bg-gray-900 border border-gray-800',
    headerTitle: 'text-white',
    headerSubtitle: 'text-gray-400',
    socialButtonsBlockButton: 'bg-gray-800 border border-gray-700 text-white hover:bg-gray-700',
    formButtonPrimary: 'bg-emerald-600 hover:bg-emerald-700',
    footerActionLink: 'text-emerald-500 hover:text-emerald-400',
    formFieldInput: 'bg-gray-800 border-gray-700 text-white',
    formFieldLabel: 'text-gray-400',
    dividerLine: 'bg-gray-800',
    dividerText: 'text-gray-400'
  }
};

export default function App() {
  return (
    <ClerkProvider 
      publishableKey={clerkPubKey}
      appearance={customAppearance}
    >
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/dashboard/*"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/*"
              element={
                <AdminRoute>
                  <Admin />
                </AdminRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ChatBot />
        </AuthProvider>
      </BrowserRouter>
    </ClerkProvider>
  );
}