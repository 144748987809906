import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useUser } from '@clerk/clerk-react';
import { MessageCircle, X, Send, Search, Circle } from 'lucide-react';

interface ChatMessage {
  id: string;
  userId: string;
  userEmail: string;
  text: string;
  isAdmin: boolean;
  timestamp: Date;
  read: boolean;
}

interface ChatSession {
  userId: string;
  userEmail: string;
  lastMessage: string;
  unreadCount: number;
  lastActivity: Date;
  isOnline: boolean;
}

const userQuestions = [
  "What's the minimum investment amount?",
  "How do I withdraw my funds?",
  "Is my investment secure?",
  "Can you explain the weekly returns?",
  "How long does withdrawal take?",
  "What cryptocurrencies do you accept?"
];

export default function AdminChat() {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [activeChat, setActiveChat] = useState<string | null>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [input, setInput] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [chatSessions, setChatSessions] = useState<ChatSession[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [notification, setNotification] = useState<{ message: string; show: boolean }>({
    message: '',
    show: false
  });

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  // Initialize chat sessions once
  useEffect(() => {
    const initialSessions: ChatSession[] = [
      {
        userId: 'user1',
        userEmail: 'john.doe@example.com',
        lastMessage: 'How do I withdraw my funds?',
        unreadCount: 2,
        lastActivity: new Date(),
        isOnline: true
      },
      {
        userId: 'user2',
        userEmail: 'sarah.wilson@example.com',
        lastMessage: "What's the minimum investment?",
        unreadCount: 1,
        lastActivity: new Date(Date.now() - 1000 * 60 * 30),
        isOnline: false
      }
    ];
    setChatSessions(initialSessions);
  }, []);

  // Handle real-time updates
  useEffect(() => {
    if (!chatSessions.length) return;

    const intervals: number[] = [];

    // Simulate new messages
    intervals.push(window.setInterval(() => {
      const randomSession = chatSessions[Math.floor(Math.random() * chatSessions.length)];
      if (!randomSession) return;

      const randomQuestion = userQuestions[Math.floor(Math.random() * userQuestions.length)];
      
      setChatSessions(prev => prev.map(session => 
        session.userId === randomSession.userId
          ? {
              ...session,
              lastMessage: randomQuestion,
              unreadCount: session.unreadCount + (activeChat !== session.userId ? 1 : 0),
              lastActivity: new Date()
            }
          : session
      ));

      if (activeChat === randomSession.userId) {
        const newMessage: ChatMessage = {
          id: Date.now().toString(),
          userId: randomSession.userId,
          userEmail: randomSession.userEmail,
          text: randomQuestion,
          isAdmin: false,
          timestamp: new Date(),
          read: false
        };
        setMessages(prev => [...prev, newMessage]);
      } else if (!isOpen) {
        setNotification({
          message: `New message from ${randomSession.userEmail}`,
          show: true
        });
        setTimeout(() => setNotification({ message: '', show: false }), 3000);
      }
    }, 15000));

    // Simulate online status changes
    intervals.push(window.setInterval(() => {
      setChatSessions(prev => prev.map(session => ({
        ...session,
        isOnline: Math.random() > 0.3
      })));
    }, 8000));

    return () => intervals.forEach(clearInterval);
  }, [chatSessions.length, activeChat, isOpen]);

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  // Load chat history when switching chats
  useEffect(() => {
    if (!activeChat) return;

    const session = chatSessions.find(s => s.userId === activeChat);
    if (!session) return;

    setChatSessions(prev => prev.map(s => 
      s.userId === activeChat
        ? { ...s, unreadCount: 0 }
        : s
    ));

    const chatHistory: ChatMessage[] = [
      {
        id: '1',
        userId: activeChat,
        userEmail: session.userEmail,
        text: 'Hello, I need help with my investment',
        isAdmin: false,
        timestamp: new Date(Date.now() - 1000 * 60 * 5),
        read: true
      },
      {
        id: '2',
        userId: activeChat,
        userEmail: session.userEmail,
        text: 'What would you like to know?',
        isAdmin: true,
        timestamp: new Date(Date.now() - 1000 * 60 * 4),
        read: true
      }
    ];
    setMessages(chatHistory);
  }, [activeChat]);

  const handleSend = useCallback(() => {
    if (!input.trim() || !activeChat) return;

    const session = chatSessions.find(s => s.userId === activeChat);
    if (!session) return;

    const newMessage: ChatMessage = {
      id: Date.now().toString(),
      userId: activeChat,
      userEmail: session.userEmail,
      text: input,
      isAdmin: true,
      timestamp: new Date(),
      read: true
    };

    setMessages(prev => [...prev, newMessage]);
    setChatSessions(prev => prev.map(s => 
      s.userId === activeChat
        ? { ...s, lastMessage: input, lastActivity: new Date() }
        : s
    ));
    setInput('');
  }, [input, activeChat, chatSessions]);

  const filteredSessions = useMemo(() => 
    chatSessions
      .filter(session =>
        session.userEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        session.lastMessage.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => b.lastActivity.getTime() - a.lastActivity.getTime()),
    [chatSessions, searchTerm]
  );

  if (!user || user.publicMetadata.role !== 'admin') {
    return null;
  }

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 bg-emerald-600 text-white p-4 rounded-full shadow-lg hover:bg-emerald-700 transition-colors z-50"
      >
        <MessageCircle className="h-6 w-6" />
        {chatSessions.reduce((sum, session) => sum + session.unreadCount, 0) > 0 && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
            {chatSessions.reduce((sum, session) => sum + session.unreadCount, 0)}
          </span>
        )}
      </button>

      {notification.show && (
        <div className="fixed bottom-20 right-4 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg z-50 animate-fade-in">
          {notification.message}
        </div>
      )}

      {isOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-gray-900 rounded-xl border border-gray-800 w-full max-w-4xl h-[600px] flex">
            {/* Chat List */}
            <div className="w-80 border-r border-gray-800">
              <div className="p-4 border-b border-gray-800">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search chats..."
                    className="w-full pl-10 pr-4 py-2 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500"
                  />
                </div>
              </div>
              <div className="overflow-y-auto h-[calc(600px-73px)]">
                {filteredSessions.map((session) => (
                  <button
                    key={session.userId}
                    onClick={() => setActiveChat(session.userId)}
                    className={`w-full p-4 text-left hover:bg-gray-800/50 ${
                      activeChat === session.userId ? 'bg-gray-800' : ''
                    }`}
                  >
                    <div className="flex items-center justify-between mb-1">
                      <span className="font-medium text-white">{session.userEmail}</span>
                      <div className="flex items-center">
                        <Circle className={`h-2 w-2 ${
                          session.isOnline ? 'text-emerald-500' : 'text-gray-500'
                        }`} />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm text-gray-400 truncate">{session.lastMessage}</p>
                      {session.unreadCount > 0 && (
                        <span className="bg-emerald-600 text-white text-xs px-2 py-1 rounded-full">
                          {session.unreadCount}
                        </span>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {/* Chat Area */}
            <div className="flex-1 flex flex-col">
              <div className="p-4 border-b border-gray-800 flex justify-between items-center">
                <div>
                  {activeChat ? (
                    <>
                      <h3 className="text-lg font-semibold text-white">
                        {chatSessions.find(s => s.userId === activeChat)?.userEmail}
                      </h3>
                      <p className="text-sm text-gray-400">
                        {chatSessions.find(s => s.userId === activeChat)?.isOnline 
                          ? 'Online' 
                          : 'Offline'}
                      </p>
                    </>
                  ) : (
                    <h3 className="text-lg font-semibold text-white">Select a chat</h3>
                  )}
                </div>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>

              <div className="flex-1 overflow-y-auto p-4 space-y-4">
                {activeChat ? (
                  messages.map((message) => (
                    <div
                      key={message.id}
                      className={`flex ${message.isAdmin ? 'justify-end' : 'justify-start'}`}
                    >
                      <div
                        className={`max-w-[80%] p-3 rounded-lg ${
                          message.isAdmin
                            ? 'bg-emerald-600 text-white'
                            : 'bg-gray-800 text-white'
                        }`}
                      >
                        <p>{message.text}</p>
                        <p className="text-xs mt-1 opacity-75">
                          {message.timestamp.toLocaleTimeString()}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="h-full flex items-center justify-center text-gray-400">
                    Select a chat to start messaging
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>

              {activeChat && (
                <div className="p-4 border-t border-gray-800">
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
                      placeholder="Type your message..."
                      className="flex-1 bg-gray-800 text-white rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                    />
                    <button
                      onClick={handleSend}
                      disabled={!input.trim()}
                      className="bg-emerald-600 text-white p-2 rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <Send className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}