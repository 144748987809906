import { create } from 'zustand';

interface CryptoRate {
  rate: number;
  symbol: string;
  change24h: number;
}

interface CryptoRates {
  BTC: CryptoRate;
  ETH: CryptoRate;
  USDT: CryptoRate;
}

interface PaymentStatus {
  status: 'pending' | 'confirmed' | 'failed';
  txHash?: string;
  confirmations?: number;
}

interface CryptoStore {
  rates: CryptoRates;
  lastUpdated: Date | null;
  isLoading: boolean;
  error: string | null;
  payments: Map<string, PaymentStatus>;
  fetchRates: () => Promise<void>;
  startPaymentMonitoring: (paymentId: string, address: string, expectedAmount: number, crypto: string) => void;
  stopPaymentMonitoring: (paymentId: string) => void;
}

const CRYPTOCOMPARE_API_KEY = 'e3de8838c6120002eda83dd4df3d67ea6da9a4499c38336df5390383d844cc66';
const API_BASE_URL = 'https://min-api.cryptocompare.com/data';

// Fallback rates in case API fails
const fallbackRates: CryptoRates = {
  BTC: { rate: 65000, symbol: '₿', change24h: 0 },
  ETH: { rate: 3500, symbol: 'Ξ', change24h: 0 },
  USDT: { rate: 1, symbol: '₮', change24h: 0 }
};

// Rate limit handling
let lastApiCall = 0;
const MIN_API_INTERVAL = 2000; // Minimum 2 seconds between calls

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const useCryptoStore = create<CryptoStore>((set, get) => ({
  rates: fallbackRates,
  lastUpdated: null,
  isLoading: false,
  error: null,
  payments: new Map(),

  fetchRates: async () => {
    const now = Date.now();
    const timeSinceLastCall = now - lastApiCall;
    
    if (timeSinceLastCall < MIN_API_INTERVAL) {
      await wait(MIN_API_INTERVAL - timeSinceLastCall);
    }

    set({ isLoading: true });
    
    try {
      lastApiCall = Date.now();
      
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);

      const response = await fetch(
        `${API_BASE_URL}/pricemultifull?fsyms=BTC,ETH&tsyms=USD`,
        {
          headers: {
            'Authorization': `Apikey ${CRYPTOCOMPARE_API_KEY}`,
            'Accept': 'application/json',
          },
          signal: controller.signal
        }
      );

      clearTimeout(timeoutId);

      if (!response.ok) {
        if (response.status === 429) {
          throw new Error('Rate limit exceeded. Please try again in a few seconds.');
        }
        throw new Error(`Failed to fetch rates: ${response.statusText}`);
      }

      const data = await response.json();
      
      if (!data.RAW?.BTC?.USD || !data.RAW?.ETH?.USD) {
        throw new Error('Invalid response format');
      }

      set({
        rates: {
          BTC: { 
            rate: data.RAW.BTC.USD.PRICE, 
            symbol: '₿',
            change24h: data.RAW.BTC.USD.CHANGEPCT24HOUR
          },
          ETH: { 
            rate: data.RAW.ETH.USD.PRICE, 
            symbol: 'Ξ',
            change24h: data.RAW.ETH.USD.CHANGEPCT24HOUR
          },
          USDT: { 
            rate: 1, 
            symbol: '₮',
            change24h: 0
          }
        },
        lastUpdated: new Date(),
        error: null
      });
    } catch (error) {
      console.error('Error fetching crypto rates:', error);
      set({ 
        error: error instanceof Error ? error.message : 'Failed to fetch rates',
        rates: get().lastUpdated ? get().rates : fallbackRates // Keep last successful rates if available
      });
    } finally {
      set({ isLoading: false });
    }
  },

  startPaymentMonitoring: (paymentId: string, address: string, expectedAmount: number, crypto: string) => {
    const store = get();
    store.payments.set(paymentId, { status: 'pending' });
    set({ payments: new Map(store.payments) });

    // Start monitoring the address for incoming transactions
    const checkPayment = async () => {
      try {
        const now = Date.now();
        const timeSinceLastCall = now - lastApiCall;
        
        if (timeSinceLastCall < MIN_API_INTERVAL) {
          await wait(MIN_API_INTERVAL - timeSinceLastCall);
        }

        lastApiCall = Date.now();

        const response = await fetch(
          `${API_BASE_URL}/blockchain/address/txs?address=${address}&api_key=${CRYPTOCOMPARE_API_KEY}`,
          {
            headers: {
              'Accept': 'application/json',
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.Data?.incomingTxs?.length > 0) {
          const latestTx = data.Data.incomingTxs[0];
          const currentAmount = crypto === 'USDT' ? 
            latestTx.value : 
            latestTx.value / (crypto === 'BTC' ? 100000000 : 1000000000000000000);

          if (currentAmount >= expectedAmount) {
            store.payments.set(paymentId, { 
              status: 'confirmed',
              txHash: latestTx.hash,
              confirmations: latestTx.confirmations
            });
            set({ payments: new Map(store.payments) });
            store.stopPaymentMonitoring(paymentId);
          }
        }
      } catch (error) {
        console.error('Payment monitoring error:', error);
      }
    };

    // Check every 30 seconds
    const intervalId = setInterval(checkPayment, 30000);
    checkPayment(); // Initial check

    // Store the interval ID for cleanup
    (window as any)[`payment_${paymentId}`] = intervalId;

    // Set a timeout to fail the payment if not confirmed within 30 minutes
    setTimeout(() => {
      const currentStatus = store.payments.get(paymentId);
      if (currentStatus?.status === 'pending') {
        store.payments.set(paymentId, { status: 'failed' });
        set({ payments: new Map(store.payments) });
        store.stopPaymentMonitoring(paymentId);
      }
    }, 30 * 60 * 1000);
  },

  stopPaymentMonitoring: (paymentId: string) => {
    const intervalId = (window as any)[`payment_${paymentId}`];
    if (intervalId) {
      clearInterval(intervalId);
      delete (window as any)[`payment_${paymentId}`];
    }
  }
}));