import React, { useState, useEffect, useCallback } from 'react';
import { TrendingUp, TrendingDown, AlertCircle, RefreshCw } from 'lucide-react';

interface CryptoData {
  id: string;
  name: string;
  symbol: string;
  current_price: number;
  price_change_percentage_24h: number;
  market_cap: number;
  total_volume: number;
  network?: string;
}

const CRYPTOCOMPARE_API_KEY = 'e3de8838c6120002eda83dd4df3d67ea6da9a4499c38336df5390383d844cc66';
const API_BASE_URL = 'https://min-api.cryptocompare.com/data';

export default function Markets() {
  const [cryptoData, setCryptoData] = useState<CryptoData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  const [retryCount, setRetryCount] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);

      const response = await fetch(
        `${API_BASE_URL}/pricemultifull?fsyms=BTC,ETH,USDT&tsyms=USD`,
        {
          headers: {
            'Authorization': `Apikey ${CRYPTOCOMPARE_API_KEY}`,
            'Accept': 'application/json',
          },
          signal: controller.signal
        }
      );

      clearTimeout(timeoutId);

      if (!response.ok) {
        if (response.status === 429) {
          throw new Error('Rate limit exceeded. Using cached data.');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data.RAW) {
        throw new Error('Invalid response format');
      }

      const formattedData: CryptoData[] = Object.entries(data.RAW).map(([symbol, details]: [string, any]) => ({
        id: symbol.toLowerCase(),
        name: symbol === 'BTC' ? 'Bitcoin' : symbol === 'ETH' ? 'Ethereum' : 'USDT',
        symbol: symbol.toLowerCase(),
        current_price: details.USD.PRICE,
        price_change_percentage_24h: details.USD.CHANGEPCT24HOUR,
        market_cap: details.USD.MKTCAP,
        total_volume: details.USD.TOTALVOLUME24H,
        network: symbol === 'USDT' ? 'TRC20' : undefined
      }));

      setCryptoData(formattedData);
      setError(null);
      setLastUpdate(new Date());
      setRetryCount(0);
    } catch (error) {
      console.error('Error fetching crypto data:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Failed to fetch market data');
      }
      
      // Implement exponential backoff for retries
      if (retryCount < 3) {
        const backoffTime = Math.pow(2, retryCount) * 1000;
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
          fetchData();
        }, backoffTime);
      }
    } finally {
      setIsLoading(false);
    }
  }, [retryCount]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 30000); // Fetch every 30 seconds
    return () => clearInterval(interval);
  }, [fetchData]);

  const handleRefresh = () => {
    setIsLoading(true);
    setRetryCount(0);
    fetchData();
  };

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-black/40 rounded-xl border border-gray-800 overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-white">Market Overview</h2>
              <div className="flex items-center space-x-4">
                {error ? (
                  <div className="flex items-center text-amber-500">
                    <AlertCircle className="h-5 w-5 mr-2" />
                    <span className="text-sm">{error}</span>
                    <button
                      onClick={handleRefresh}
                      className="ml-2 p-1 hover:bg-gray-800 rounded-full transition-colors"
                    >
                      <RefreshCw className="h-4 w-4" />
                    </button>
                  </div>
                ) : (
                  <span className="text-sm text-gray-400">
                    Last updated: {lastUpdate.toLocaleTimeString()}
                  </span>
                )}
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left text-sm text-gray-400">
                    <th className="pb-4 px-6">Asset</th>
                    <th className="pb-4 px-6">Price</th>
                    <th className="pb-4 px-6">24h Change</th>
                    <th className="pb-4 px-6">Market Cap</th>
                    <th className="pb-4 px-6">Volume (24h)</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {cryptoData.map((crypto) => (
                    <tr key={crypto.id} className="hover:bg-gray-800/30">
                      <td className="py-4 px-6">
                        <div>
                          <span className="text-white font-medium">
                            {crypto.name} ({crypto.symbol.toUpperCase()})
                          </span>
                          {crypto.network && (
                            <span className="ml-2 text-xs bg-gray-700 text-gray-300 px-2 py-1 rounded-full">
                              {crypto.network}
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="py-4 px-6">
                        <span className="text-white">
                          ${crypto.current_price.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </span>
                      </td>
                      <td className="py-4 px-6">
                        <div className="flex items-center">
                          {crypto.price_change_percentage_24h > 0 ? (
                            <TrendingUp className="h-4 w-4 text-emerald-500 mr-1" />
                          ) : (
                            <TrendingDown className="h-4 w-4 text-red-500 mr-1" />
                          )}
                          <span
                            className={crypto.price_change_percentage_24h > 0 ? 'text-emerald-500' : 'text-red-500'}
                          >
                            {Math.abs(crypto.price_change_percentage_24h).toFixed(2)}%
                          </span>
                        </div>
                      </td>
                      <td className="py-4 px-6">
                        <span className="text-white">
                          ${(crypto.market_cap / 1e9).toFixed(2)}B
                        </span>
                      </td>
                      <td className="py-4 px-6">
                        <span className="text-white">
                          ${(crypto.total_volume / 1e9).toFixed(2)}B
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}