import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { Users, DollarSign, ArrowUpRight, Clock, AlertCircle, RefreshCw, Check, X as XIcon } from 'lucide-react';
import { ErrorBoundary } from 'react-error-boundary';
import CountUp from 'react-countup';

interface Investment {
  id: string;
  userId: string;
  amount: number;
  type: 'weekly' | 'monthly';
  status: 'active' | 'completed';
  createdAt: Date;
}

interface WithdrawalRequest {
  id: string;
  userId: string;
  userEmail: string;
  amount: number;
  cryptoType: string;
  walletAddress: string;
  status: 'pending' | 'approved' | 'rejected';
  createdAt: Date;
}

interface Activity {
  id: string;
  type: string;
  userId: string;
  userEmail: string;
  details: string;
  timestamp: Date;
}

function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  return (
    <div className="min-h-screen bg-gray-900 pt-16 flex items-center justify-center px-4">
      <div className="bg-red-500/10 border border-red-500 text-red-500 px-6 py-4 rounded-lg max-w-md">
        <div className="flex items-center mb-4">
          <AlertCircle className="h-5 w-5 mr-2" />
          <h3 className="font-semibold">Error</h3>
        </div>
        <p className="mb-4">{error.message}</p>
        <button
          onClick={resetErrorBoundary}
          className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
        >
          <RefreshCw className="h-4 w-4 mr-2" />
          Try again
        </button>
      </div>
    </div>
  );
}

function AdminDashboardContent() {
  const { user, isLoaded } = useUser();
  const [activeTab, setActiveTab] = useState<'overview' | 'investments' | 'withdrawals'>('overview');
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalInvestments: 0,
    activeInvestments: 0,
    pendingWithdrawals: 0
  });
  const [recentActivity, setRecentActivity] = useState<Activity[]>([]);
  const [withdrawalRequests, setWithdrawalRequests] = useState<WithdrawalRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Simulated data for demonstration
  useEffect(() => {
    let isMounted = true;
    let interval: number;

    const generateRandomData = () => {
      if (!isMounted) return;

      const newStats = {
        totalUsers: Math.floor(Math.random() * 1000) + 500,
        totalInvestments: Math.floor(Math.random() * 5000000) + 1000000,
        activeInvestments: Math.floor(Math.random() * 200) + 50,
        pendingWithdrawals: Math.floor(Math.random() * 20) + 5
      };

      const newActivities: Activity[] = Array.from({ length: 10 }, (_, i) => ({
        id: `act-${Date.now() + i}`,
        type: Math.random() > 0.5 ? 'investment' : 'withdrawal',
        userId: `user${Math.floor(Math.random() * 1000)}`,
        userEmail: `user${Math.floor(Math.random() * 1000)}@example.com`,
        details: Math.random() > 0.5 
          ? `New investment of $${(Math.random() * 10000).toFixed(2)}`
          : `Withdrawal request of $${(Math.random() * 5000).toFixed(2)}`,
        timestamp: new Date(Date.now() - Math.random() * 86400000)
      }));

      const newWithdrawals: WithdrawalRequest[] = Array.from({ length: 8 }, (_, i) => ({
        id: `wd-${Date.now() + i}`,
        userId: `user${Math.floor(Math.random() * 1000)}`,
        userEmail: `user${Math.floor(Math.random() * 1000)}@example.com`,
        amount: Math.floor(Math.random() * 10000) + 1000,
        cryptoType: ['BTC', 'ETH', 'USDT'][Math.floor(Math.random() * 3)],
        walletAddress: '0x' + Math.random().toString(36).substring(2, 15),
        status: ['pending', 'approved', 'rejected'][Math.floor(Math.random() * 3)] as 'pending' | 'approved' | 'rejected',
        createdAt: new Date(Date.now() - Math.random() * 86400000)
      }));

      setStats(newStats);
      setRecentActivity(newActivities);
      setWithdrawalRequests(newWithdrawals);
      setIsLoading(false);
    };

    // Initial generation
    generateRandomData();

    // Update every 30 seconds
    interval = window.setInterval(generateRandomData, 30000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, []);

  const handleWithdrawalAction = (requestId: string, action: 'approve' | 'reject') => {
    setWithdrawalRequests(prev => prev.map(request => 
      request.id === requestId
        ? { ...request, status: action === 'approve' ? 'approved' : 'rejected' }
        : request
    ));

    const request = withdrawalRequests.find(r => r.id === requestId);
    if (request) {
      setRecentActivity(prev => [{
        id: `act-${Date.now()}`,
        type: 'withdrawal',
        userId: request.userId,
        userEmail: request.userEmail,
        details: `Withdrawal request ${action === 'approve' ? 'approved' : 'rejected'} for $${request.amount}`,
        timestamp: new Date()
      }, ...prev]);
    }
  };

  if (!isLoaded) {
    return (
      <div className="min-h-screen bg-gray-900 pt-16 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  if (error) {
    throw error;
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Stats Overview */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <div className="bg-black/40 rounded-xl border border-gray-800 p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-400">Total Users</p>
                <h3 className="text-2xl font-bold text-white">
                  <CountUp end={stats.totalUsers} />
                </h3>
              </div>
              <Users className="h-8 w-8 text-emerald-500" />
            </div>
          </div>

          <div className="bg-black/40 rounded-xl border border-gray-800 p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-400">Total Investments</p>
                <h3 className="text-2xl font-bold text-white">
                  ${<CountUp end={stats.totalInvestments} separator="," />}
                </h3>
              </div>
              <DollarSign className="h-8 w-8 text-blue-500" />
            </div>
          </div>

          <div className="bg-black/40 rounded-xl border border-gray-800 p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-400">Active Investments</p>
                <h3 className="text-2xl font-bold text-white">
                  <CountUp end={stats.activeInvestments} />
                </h3>
              </div>
              <ArrowUpRight className="h-8 w-8 text-amber-500" />
            </div>
          </div>

          <div className="bg-black/40 rounded-xl border border-gray-800 p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-400">Pending Withdrawals</p>
                <h3 className="text-2xl font-bold text-white">
                  <CountUp end={stats.pendingWithdrawals} />
                </h3>
              </div>
              <Clock className="h-8 w-8 text-purple-500" />
            </div>
          </div>
        </div>

        {/* Tab Navigation */}
        <div className="bg-black/40 rounded-xl border border-gray-800 p-6 mb-8">
          <div className="flex space-x-4 mb-6">
            <button
              onClick={() => setActiveTab('overview')}
              className={`px-4 py-2 rounded-lg ${
                activeTab === 'overview'
                  ? 'bg-emerald-600 text-white'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('withdrawals')}
              className={`px-4 py-2 rounded-lg ${
                activeTab === 'withdrawals'
                  ? 'bg-emerald-600 text-white'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              Withdrawals
            </button>
          </div>

          {/* Tab Content */}
          {activeTab === 'overview' && (
            <div className="space-y-6">
              <div className="bg-gray-800/50 rounded-lg p-4">
                <h3 className="text-lg font-medium text-white mb-4">Recent Activity</h3>
                <div className="space-y-4">
                  {recentActivity.map((activity) => (
                    <div
                      key={activity.id}
                      className="flex items-center justify-between py-3 border-b border-gray-700 last:border-0"
                    >
                      <div>
                        <p className="text-white">{activity.userEmail}</p>
                        <p className="text-sm text-gray-400">{activity.details}</p>
                      </div>
                      <span className="text-sm text-gray-400">
                        {activity.timestamp.toLocaleString()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'withdrawals' && (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left text-gray-400">
                    <th className="px-6 py-3">User</th>
                    <th className="px-6 py-3">Amount</th>
                    <th className="px-6 py-3">Crypto</th>
                    <th className="px-6 py-3">Wallet Address</th>
                    <th className="px-6 py-3">Status</th>
                    <th className="px-6 py-3">Date</th>
                    <th className="px-6 py-3">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {withdrawalRequests.map((request) => (
                    <tr key={request.id} className="text-white">
                      <td className="px-6 py-4">{request.userEmail}</td>
                      <td className="px-6 py-4">${request.amount.toLocaleString()}</td>
                      <td className="px-6 py-4">{request.cryptoType}</td>
                      <td className="px-6 py-4">
                        <span className="font-mono text-sm">{request.walletAddress}</span>
                      </td>
                      <td className="px-6 py-4">
                        <span className={`px-2 py-1 text-xs rounded-full ${
                          request.status === 'pending' ? 'bg-yellow-500/20 text-yellow-500' :
                          request.status === 'approved' ? 'bg-emerald-500/20 text-emerald-500' :
                          'bg-red-500/20 text-red-500'
                        }`}>
                          {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        {request.createdAt.toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4">
                        {request.status === 'pending' && (
                          <div className="flex space-x-2">
                            <button
                              onClick={() => handleWithdrawalAction(request.id, 'approve')}
                              className="p-1 text-emerald-500 hover:bg-emerald-500/20 rounded"
                              title="Approve"
                            >
                              <Check className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleWithdrawalAction(request.id, 'reject')}
                              className="p-1 text-red-500 hover:bg-red-500/20 rounded"
                              title="Reject"
                            >
                              <XIcon className="h-5 w-5" />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default function AdminDashboard() {
  return (
    <ErrorBoundary 
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <AdminDashboardContent />
    </ErrorBoundary>
  );
}