import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, useClerk } from '@clerk/clerk-react';
import { Shield, Users, Settings, LogOut } from 'lucide-react';

export default function AdminNavbar() {
  const navigate = useNavigate();
  const { user } = useUser();
  const { signOut } = useClerk();

  const handleLogout = () => {
    signOut();
    navigate('/');
  };

  return (
    <nav className="bg-black/95 fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0 flex items-center">
              <Shield className="h-8 w-8 text-emerald-500" />
              <span className="ml-2 text-white font-bold">Admin Portal</span>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <button
                  onClick={() => navigate('/admin')}
                  className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white"
                >
                  Dashboard
                </button>
                <button
                  onClick={() => navigate('/admin/setup')}
                  className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white"
                >
                  Setup
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <div className="ml-3 flex items-center">
                <span className="text-sm font-medium text-white mr-4">
                  {user?.primaryEmailAddress?.emailAddress}
                </span>
                <button
                  onClick={handleLogout}
                  className="p-2 rounded-full text-gray-400 hover:text-white focus:outline-none"
                >
                  <LogOut className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}