import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, useClerk } from '@clerk/clerk-react';
import CountUp from 'react-countup';
import { TrendingUp, Users, DollarSign, ArrowRight, Shield, LineChart, Clock, LogIn, Star } from 'lucide-react';
import Footer from './Footer';

const testimonials = [
  {
    id: 1,
    name: "Sarah Johnson",
    role: "Professional Investor",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80",
    content: "Gold High Yield Investment has transformed my investment portfolio. The 50% monthly returns are incredible, and their platform is extremely user-friendly.",
    rating: 5
  },
  {
    id: 2,
    name: "Michael Chen",
    role: "Business Owner",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80",
    content: "I started with the minimum investment and was amazed by the consistent returns. Their customer support is exceptional, and the weekly payout option gives great flexibility.",
    rating: 5
  },
  {
    id: 3,
    name: "Emma Davis",
    role: "Financial Analyst",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&q=80",
    content: "The transparency and professionalism of Gold High Yield Investment sets them apart. I've been investing for 6 months and the returns have been consistently impressive.",
    rating: 5
  }
];

export default function LandingPage() {
  const navigate = useNavigate();
  const { isSignedIn, user } = useUser();
  const { openSignIn } = useClerk();

  React.useEffect(() => {
    if (isSignedIn && user) {
      const redirectPath = user.publicMetadata.role === 'admin' ? '/admin' : '/dashboard';
      navigate(redirectPath, { replace: true });
    }
  }, [isSignedIn, user, navigate]);

  const handleLogin = () => {
    openSignIn({
      redirectUrl: '/dashboard',
      appearance: {
        elements: {
          rootBox: 'mx-auto',
          card: 'bg-gray-900 border border-gray-800',
          headerTitle: 'text-white',
          headerSubtitle: 'text-gray-400',
          socialButtonsBlockButton: 'bg-gray-800 border border-gray-700 text-white hover:bg-gray-700',
          formButtonPrimary: 'bg-emerald-600 hover:bg-emerald-700',
          footerActionLink: 'text-emerald-500 hover:text-emerald-400'
        }
      }
    });
  };

  const calculateReturns = (amount: number, duration: number, isWeekly: boolean) => {
    const monthlyRate = isWeekly ? 1.3 : 1.5;
    return amount * Math.pow(monthlyRate, duration);
  };

  return (
    <>
      <div className="min-h-screen bg-gray-900">
        {/* Login Button */}
        <div className="fixed top-4 right-4 z-50">
          <button
            onClick={handleLogin}
            className="inline-flex items-center px-4 py-2 bg-emerald-600 rounded-lg text-white hover:bg-emerald-700 transition-colors"
          >
            <LogIn className="h-5 w-5 mr-2" />
            Login
          </button>
        </div>

        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <div className="text-center">
              <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
                Gold High Yield Investment
              </h1>
              <p className="text-xl text-gray-300 mb-8">
                Secure your financial future with industry-leading returns
              </p>
              <button
                onClick={handleLogin}
                className="inline-flex items-center px-6 py-3 bg-emerald-600 rounded-lg text-white text-lg hover:bg-emerald-700 transition-colors"
              >
                Start Investing Now
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="bg-black/40 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="text-center">
                <div className="text-3xl font-bold text-white mb-2">
                  $<CountUp end={50} duration={2} />M+
                </div>
                <p className="text-gray-400">Assets Under Management</p>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold text-white mb-2">
                  <CountUp end={125860} duration={2} />+
                </div>
                <p className="text-gray-400">Active Investors</p>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold text-white mb-2">
                  <CountUp end={50} duration={2} />%
                </div>
                <p className="text-gray-400">Monthly Returns</p>
              </div>
            </div>
          </div>
        </div>

        {/* Investment Plans */}
        <div className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-white text-center mb-12">Investment Plans</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Weekly Plan */}
              <div className="bg-black/40 p-8 rounded-xl border border-gray-800">
                <div className="flex items-center justify-between mb-6">
                  <h3 className="text-2xl font-bold text-white">Weekly Plan</h3>
                  <span className="text-emerald-500 text-xl font-bold">30% ROI</span>
                </div>
                <div className="space-y-4 mb-8">
                  <div className="flex justify-between">
                    <span className="text-gray-400">Minimum Investment</span>
                    <span className="text-white">$2,000</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">1 Month Return</span>
                    <span className="text-white">${calculateReturns(2000, 1, true).toLocaleString()}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">3 Months Return</span>
                    <span className="text-white">${calculateReturns(2000, 3, true).toLocaleString()}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">6 Months Return</span>
                    <span className="text-white">${calculateReturns(2000, 6, true).toLocaleString()}</span>
                  </div>
                </div>
                <button
                  onClick={handleLogin}
                  className="w-full px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
                >
                  Start Weekly Plan
                </button>
              </div>

              {/* Monthly Plan */}
              <div className="bg-black/40 p-8 rounded-xl border border-gray-800">
                <div className="flex items-center justify-between mb-6">
                  <h3 className="text-2xl font-bold text-white">Monthly Plan</h3>
                  <span className="text-emerald-500 text-xl font-bold">50% ROI</span>
                </div>
                <div className="space-y-4 mb-8">
                  <div className="flex justify-between">
                    <span className="text-gray-400">Minimum Investment</span>
                    <span className="text-white">$2,000</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">1 Month Return</span>
                    <span className="text-white">${calculateReturns(2000, 1, false).toLocaleString()}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">3 Months Return</span>
                    <span className="text-white">${calculateReturns(2000, 3, false).toLocaleString()}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">6 Months Return</span>
                    <span className="text-white">${calculateReturns(2000, 6, false).toLocaleString()}</span>
                  </div>
                </div>
                <button
                  onClick={handleLogin}
                  className="w-full px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
                >
                  Start Monthly Plan
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="bg-black/40 py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-6 rounded-xl border border-gray-800">
                <div className="bg-emerald-500/20 p-3 rounded-lg w-fit mb-4">
                  <Shield className="h-6 w-6 text-emerald-500" />
                </div>
                <h3 className="text-xl font-bold text-white mb-2">Secure Investment</h3>
                <p className="text-gray-400">Your investments are protected by advanced blockchain technology</p>
              </div>
              <div className="p-6 rounded-xl border border-gray-800">
                <div className="bg-emerald-500/20 p-3 rounded-lg w-fit mb-4">
                  <LineChart className="h-6 w-6 text-emerald-500" />
                </div>
                <h3 className="text-xl font-bold text-white mb-2">High Returns</h3>
                <p className="text-gray-400">Earn up to 50% monthly returns on your investments</p>
              </div>
              <div className="p-6 rounded-xl border border-gray-800">
                <div className="bg-emerald-500/20 p-3 rounded-lg w-fit mb-4">
                  <Clock className="h-6 w-6 text-emerald-500" />
                </div>
                <h3 className="text-xl font-bold text-white mb-2">Flexible Terms</h3>
                <p className="text-gray-400">Choose between weekly (30%) or monthly (50%) investment plans</p>
              </div>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-white text-center mb-12">What Our Investors Say</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="bg-black/40 p-6 rounded-xl border border-gray-800">
                  <div className="flex items-center mb-4">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="h-12 w-12 rounded-full object-cover"
                    />
                    <div className="ml-4">
                      <h4 className="text-lg font-semibold text-white">{testimonial.name}</h4>
                      <p className="text-sm text-gray-400">{testimonial.role}</p>
                    </div>
                  </div>
                  <div className="flex mb-4">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="h-5 w-5 text-amber-400 fill-current" />
                    ))}
                  </div>
                  <p className="text-gray-300">{testimonial.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}